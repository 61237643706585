import React from 'react';
import PropTypes from 'prop-types';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { trans } from '../../modules/translation/translate';
import { enhanceComponent } from '../../wrappers/enhanceComponent';
import { AwesomeIcon } from '../../../../common/components/icons/AwesomeIcon';
import {
	CheckboxStyle,
	CheckboxWrapperStyle,
	StyledCheckboxBox,
} from './checkbox.style';

class Checkbox extends React.PureComponent {
	static propTypes = {
		label: PropTypes.any,
		initValue: PropTypes.any,
		onChange: PropTypes.func,
		update: PropTypes.bool,
		isError: PropTypes.any,
		disabled: PropTypes.bool,
		className: PropTypes.string,
		onlyCheckboxClick: PropTypes.bool,
		color: PropTypes.bool,
	};

	constructor(props) {
		super(props);
		this.state = {
			value: props.initValue,
		};
	}

	componentDidMount() {
		if (this.props.initValue !== undefined) {
			this.setState({
				value: this.props.initValue,
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// console.log('componentDidUpdate', this.props.label, {
		// 	currProp: this.props.initValue,
		// 	currState: this.state.value,
		// 	prevState: prevState.value,
		// 	prevProp: prevProps.initValue
		// });
		if (
			prevProps.initValue !== this.props.initValue &&
			prevState.value === this.state.value &&
			this.props.initValue !== this.state.value &&
			this.props.update &&
			this.props.initValue !== undefined
		) {
			this.setState({
				value: this.props.initValue,
			});
		}
	}

	handleCheckbox = (event) => {
		// console.log('handleToggle', event.target.localName, this.props, this.state);
		if (this.props.disabled || event.target.localName === 'a') {
			return;
		}
		if (this.props.onChange) {
			this.props.onChange(!this.state.value);
		}
		this.setState({
			value: !this.state.value,
		});
	};

	handleCheckboxWrapper = (event) =>
		!this.props.onlyCheckboxClick && this.handleCheckbox(event);

	handleCheckboxInside = (event) =>
		this.props.onlyCheckboxClick && this.handleCheckbox(event);

	onChange = () => {
		if (!this.props.onChange || this.props.disabled) {
			this.setState({
				value: this.props.initValue,
			});
		}
	};

	render() {
		return (
			<CheckboxStyle
				className={this.props.className}
				onClick={this.handleCheckboxWrapper}
			>
				<CheckboxWrapperStyle onClick={this.handleCheckboxInside}>
					<StyledCheckboxBox
						isError={this.props.isError}
						isChecked={this.state.value}
						isColor={this.props.color}
					>
						{this.state.value && <AwesomeIcon icon={faCheck} />}
					</StyledCheckboxBox>
					<input
						{...this.props}
						type="checkbox"
						value={this.state.value}
						onChange={this.onChange}
					/>
				</CheckboxWrapperStyle>
				{this.props.label && (
					<label className="text-style-sm-regular">
						{typeof this.props.label === 'string'
							? trans({ label: this.props.label })
							: this.props.label}
					</label>
				)}
			</CheckboxStyle>
		);
	}
}

Checkbox = enhanceComponent({
	Checkbox,
});

export { Checkbox };
